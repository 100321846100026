import styled, { css } from 'styled-components';
import { media } from '@meindach/ui-kit';

interface Props {
  fullWidth?: boolean;
}

export const Cell = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.s};
  width: 100%;

  ${media.sm`
    ${({ fullWidth }: Props) =>
      !fullWidth &&
      css`
        :nth-child(even),
        :nth-child(7) {
          margin-left: 1%;
          width: 49%;
        }
        :nth-child(odd):not(:nth-child(7)),
        :nth-child(6) {
          margin-left: 0;
          width: 50%;
        }
      `}
  `}
`;
