import React, { FC } from 'react';
import { TextField } from './TextField';
import { SelectField } from './SelectField';
import Validations from '../helpers/validations';
import { Field, Formik } from 'formik';
import { StyledForm, Button } from './styled';
import {
  company,
  email,
  firstName,
  formTitle,
  lastName,
  partnerBrand,
  password,
  phone,
  validationMessageGeneric,
} from '../i18n/de.json';

interface Props {
  onSubmit: (data: any) => void;
  onSubmitFail: (message: string) => void;
}

let initialValues = {
  firstName: '',
  lastName: '',
  company: '',
  phone: '',
  email: '',
  password: '',
};

export const SignUpForm: FC<Props> = ({ onSubmit, onSubmitFail }) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(data) => {
      initialValues = data;
      onSubmit(data);
    }}
  >
    {({ handleSubmit, validateForm }) => (
      <StyledForm id={'roofer_sign_up_form'} onSubmit={handleSubmit}>
        <Field
          component={TextField}
          label={firstName}
          name="firstName"
          validate={Validations.textField}
          placeholder={firstName}
        />
        <Field
          component={TextField}
          label={lastName}
          placeholder={lastName}
          name="lastName"
          validate={Validations.textField}
        />
        <Field
          component={TextField}
          label={company}
          placeholder={company}
          name="company"
          validate={Validations.textField}
        />
        <Field
          component={TextField}
          {...phone}
          type="tel"
          name="phone"
          validate={Validations.phoneField}
        />
        <Field
          component={SelectField}
          {...partnerBrand}
          options={[
            'Braas',
            'Vedag',
            'Wolfin',
            'Icopal',
            'Isover',
            'Rathscheck',
            'Rheinzink',
            'Sonstige',
          ].map((option) => ({
            label: option,
            value: option.toLowerCase(),
          }))}
          name="partnerBrand"
          validate={Validations.selectField}
        />
        <Field
          component={TextField}
          label={email}
          placeholder={email}
          type="email"
          name="email"
          validate={Validations.emailField}
        />
        <Field
          component={TextField}
          type="password"
          name="password"
          label={password}
          placeholder={password}
          validate={Validations.textField}
        />
        <Button
          type="submit"
          fullWidth
          small
          onClick={async () =>
            (await validateForm()) !== {} &&
            onSubmitFail(validationMessageGeneric)
          }
        >
          {formTitle}
        </Button>
      </StyledForm>
    )}
  </Formik>
);
