import styled, { css } from 'styled-components';

const getPlaceHolderCss = (color) => css`
  /* The placeholder styles cannot be combined because otherwise the browsers dont recognize them */
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${color};
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${color};
    opacity: 1;
  }
  ::placeholder {
    color: ${color};
    opacity: 1;
  }
`;

interface Props {
  error?: boolean;
}

export const InputText = styled.input<Props>`
  border: 1px solid
    ${({ theme, error }) => (error ? theme.color.danger : theme.color.silver)};
  color: ${({ theme, error }) =>
    error ? theme.color.danger : theme.color.darkGrey};
  height: 44px;
  padding: 0 ${({ theme }) => theme.spacing.s};
  box-sizing: border-box;
  border-radius: 2px;

  &:focus {
    outline: 0;
  }
  ${({ theme, error }) =>
    error
      ? getPlaceHolderCss(theme.color.danger)
      : getPlaceHolderCss(theme.color.silver)};
`;
