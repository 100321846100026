import styled from 'styled-components';
import { media, Select, Button as BaseButton } from '@meindach/ui-kit';

export const Button = styled(BaseButton)`
  margin-top: ${({ theme }) => theme.spacing.s};

  > span {
    font-size: 16px;
  }
`;

export const StyledForm = styled.form`
  background-color: ${({ theme }) => theme.color.lightest};
  padding: ${({ theme }) => theme.spacing.m};
  border-radius: 5px;

  margin: 0 auto ${({ theme }) => theme.spacing.s};
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.color.silver};

  display: flex;
  flex-wrap: wrap;
`;

export const StyledSelect = styled(Select)`
  label {
    font-size: 14px;
    font-weight: 400;
    ${media.md`
      font-size: 14px;
      font-weight: 400;
    `}
  }
`;
