import React from 'react';
import { Label } from './Label';
import { InputText } from './InputText';
import { Cell } from './Cell';

export const TextField = ({ field, form, label, placeholder, type }) => (
  <Cell>
    <Label htmlFor={field.name}>{label}</Label>
    <InputText
      placeholder={placeholder}
      type={type}
      {...field}
      error={
        !!form.errors[field.name] &&
        (form.submitCount > 0 || !!form.touched[field.name])
      }
    />
  </Cell>
);
