import './Polyfills';
import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { App } from './components/App';
import { GlobalStyle } from './styles/GlobalStyle';
import { theme } from '@meindach/ui-kit';

const node = document.getElementById('roofer_sign_up_container');
if (node) {
  render(
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <App />
      </>
    </ThemeProvider>,
    node,
  );
}
