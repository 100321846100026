import React, { FC } from 'react';
import { Container, Close } from './styled';

interface Props {
  message: string;
  onClick: () => void;
}

export const Notification: FC<Props> = ({ onClick, message }) => (
  <Container>
    <p>{message}</p>
    <Close onClick={onClick} />
  </Container>
);
