import { useState } from 'react';

export const useNotification = () => {
  const [message, setMessage] = useState<string | null>(null);
  return {
    message,
    set: (
      message: string = 'Ein technisches Problem ist aufgetreten. Bitte probieren Sie es noch mal.',
    ) => setMessage(message),
    clear: () => setMessage(null),
  };
};
