import { breakpoints } from '@meindach/ui-kit';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@meindach/ui-kit/dist/assets/ui/close.svg';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.danger};
  width: 100%;
  max-width: ${breakpoints.md}px;
  margin: 0 auto ${({ theme }) => theme.spacing.m};
  color: ${({ theme }) => theme.color.lightest};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  box-sizing: border-box;
  height: 44px;
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
  padding: 10px;
  margin-right: -10px;
`;
