import React from 'react';
import { Label } from './Label';
import { Cell } from './Cell';
import { StyledSelect } from './styled';

export const SelectField = ({ field, label, form, options, placeholder }) => (
  <Cell fullWidth>
    <Label htmlFor={field.name}>{label}</Label>
    <StyledSelect
      selectedValues={field.value && [field.value]}
      onChange={(value: string) => form.setFieldValue(field.name, value)}
      onBlur={() => form.setFieldTouched(field.name, true, true)}
      options={options}
      placeHolder={placeholder}
      error={
        (form.submitCount > 0 || !!form.touched[field.name]) &&
        !!form.errors[field.name]
      }
    />
  </Cell>
);
