import React, { FC } from 'react';
import styled from 'styled-components';
import loading from '../assets/loading.gif';

interface Props {
  height?: number;
}

const StyledDiv = styled.div<Props>`
  height: ${({ height }) => height}px;
  background: url(${loading});
  background-position: center;
  background-repeat: no-repeat;
`;

export const Loading: FC<Props> = ({ height = 300 }) => (
  <StyledDiv height={height} />
);
