const ERROR_MSG = 'erforderlich';

export default {
  textField: (value?: string) =>
    value && value.length ? undefined : ERROR_MSG,
  selectField: (value?: string) => (value ? undefined : ERROR_MSG),
  phoneField: (value?: string) =>
    !value || !/-*\/*\+*\d{5,}/.test(value) ? ERROR_MSG : undefined,
  emailField: (value?: string) =>
    !value || !/\S+@\S+\.\S+/.test(value) ? ERROR_MSG : undefined,
};
