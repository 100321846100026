import { useState } from 'react';
import { useNotification } from './useNotification';
import config from '../constants/config';
import { track } from '../utils/tracking';
import { submitForm } from '../api/submitForm';

interface State {
  submitting?: boolean;
  error?: boolean;
}

export const useApi = () => {
  const [state, setState] = useState<State>({});
  const { set: setNotification } = useNotification();
  return {
    ...state,
    submit: async (form: any) => {
      setState({ submitting: true });
      try {
        const request = await submitForm(form);

        if (request.ok) {
          track({ eventLabel: 'register', eventAction: 'signup' });
          window.top.location.href = `${config.rooferDirectSignIn}`;
          return;
        }
        const { statusText } = await request.json();
        setNotification(statusText);
      } catch {
        setNotification();
      }
      setState({ error: true });
    },
  };
};
