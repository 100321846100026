import React, { memo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Normalize } from 'styled-normalize';

const DocumentStyle = createGlobalStyle`
  body {
    overflow: hidden;
    width: 100%;
    font-size: 14px;
    font-family: 'Roboto', Arial, sans-serif;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root > div {
    display: flex;
    flex-direction: column;
  }
`;

export const GlobalStyle = memo(() => (
  <>
    <Normalize />
    <DocumentStyle />
  </>
));
