import React, { useEffect, useRef, useState } from 'react';
import { Loading } from './Loading';
import { SignUpForm } from './SignUpForm';
import { Notification } from './notification/Notification';
import { useNotification } from '../hooks/useNotification';
import { useApi } from '../hooks/useApi';
import { loadGTM } from '../utils/loadGTM';

export const App = () => {
  const [height, setHeight] = useState(0);
  const { message, set, clear } = useNotification();
  const { submit, submitting } = useApi();
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!(window as any).dataLayer) {
      loadGTM();
    }
  }, []);

  useEffect(() => {
    if (!container || !container.current) {
      return;
    }
    const { clientHeight } = container.current;
    if (clientHeight === height) {
      return;
    }
    setHeight(clientHeight);
    const message = { type: 'iframeSize', payload: clientHeight };
    window.parent.postMessage(message, '*');
  }, [height]);

  if (submitting) {
    return <Loading />;
  }
  return (
    <div ref={container}>
      {message && <Notification message={message} onClick={clear} />}
      <SignUpForm onSubmit={submit} onSubmitFail={set} />
    </div>
  );
};
